<!-- MENU PRINCIPAL -->
<div style="width: 98%; margin: auto;">
  <app-menubar></app-menubar>
</div>

<!-- BREADCRUMB -->
<p-breadcrumb class="mybreadcrumb"
  [model]="comunService.getTitleBar()"
  [home]="comunService.home"
></p-breadcrumb>

<p-panel
  header=""
  [toggleable]="true"
  transitionOptions="400ms"
  class="wrapper animate__animated animate__fadeIn"
>
  <router-outlet> </router-outlet>
</p-panel>