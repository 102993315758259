import { Component, HostListener, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UserService } from '../services/user/user.service';

@Component({
  selector: 'app-change-pass',
  templateUrl: './change-pass.component.html',
  styleUrls: ['./change-pass.component.scss'],
  providers: [MessageService],
})
export class ChangePassComponent implements OnInit {
  cargado = true;
  private tokenPass: string | null = null;
  private formSubmit = false;

  public loginForm = new FormGroup(
    {
      pass: new FormControl('', [
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/
        ),
      ]),
      pass2: new FormControl('', [
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])([A-Za-z\d$@$!%*?&]|[^ ]){8,15}$/
        ),
      ]),
    },
    {
      validators: checkPass('pass', 'pass2'),
    }
  );

  constructor(
    private _userService: UserService,
    private router: Router,
    public messageService: MessageService,
    private activateRouter: ActivatedRoute
  ) {
    // obtener el tokenpass
    const misParams = this.activateRouter.queryParams;

    misParams.subscribe((params: any) => {
      if (params.user == undefined) {
        // return a home
      } else {
        this.tokenPass = params.user;
      }
    });
  }

  ngOnInit(): void {}

  @HostListener('submit', ['$event']) onSubmit(event: Event) {
    event.preventDefault();
    this.formSubmit = true;

    if (!this.loginForm.valid) {
      this.formSubmit = false;
      return this.messageService.add({
        key: 'success',
        severity: 'error',
        summary: 'MDG Audiovisuales',
        detail: 'Revise la contraseña proporcionada',
      });
    }

    const datos = {
      pass: this.loginForm.controls['pass']?.value,
      tokenPass: this.tokenPass,
    };

    // Hacemos la petición
    this.cargado = false;
    this._userService.updatePass(datos).subscribe({
      next: (resp: any) => {
        this.messageService.add({
          key: 'success',
          severity: 'success',
          summary: 'MDG Audiovisuales',
          detail: resp.message || 'Su contraseña ha sido cambiada correctamente',
        });
       

        // Ensure setTimeout is executed
        setTimeout(() => {
          this.router.navigate(['/login']).then(() => {
            console.log('Navigation to login successful!');
          }).catch((err) => {
            console.error('Navigation to login failed:', err);
          });
        }, 2000);
      },
      error: (err: any) => {
        this.messageService.add({
          key: 'success',
          severity: 'error',
          summary: 'MDG Audiovisuales',
          detail: err.message || 'se ha producido un error',
        });
        this.formSubmit = false;
        return;
      },
    });
  }

  campoNoValido(campo: any): boolean {
    if (this.loginForm.get(campo)?.invalid && this.formSubmit === true) {
      return true;
    } else {
      return false;
    }
  }
}

export function checkPass(pass: string, pass2: string): ValidatorFn {
  return (formGroup: AbstractControl): Record<string, any> | null => {
    const passwordControl = formGroup.get(pass);
    const confirmPasswordControl = formGroup.get(pass2);

    if (!passwordControl || !confirmPasswordControl) {
      return null;
    }

    if (confirmPasswordControl.errors) {
      return null;
    }

    if (passwordControl.value !== confirmPasswordControl.value) {
      confirmPasswordControl.setErrors({ mustMatch: true });
      return { noSonIguales: true };
    } else {
      confirmPasswordControl.setErrors(null);
      return null;
    }
  };
}
